<template>
  <!-- <a
    href="https://themeforest.net/item/yoda-react-admin-template-react-hooks-redux-toolkit-ant-design/33791048"
    target="_blank"
    class="hp-buy-now-btn position-fixed"
  >
    <b-button variant="primary">
      <span>🎀 &nbsp; Buy Now</span>
    </b-button>
  </a> -->
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
};
</script>
